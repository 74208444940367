import WebFont from 'webfontloader';
import { componentsInitializer } from "auth_app_vue";
import * as Components   from "./components";

componentsInitializer("data-vue-app", Components, {
  DOMContentLoaded: () => {
    WebFont.load({
      google: {
        families: ['Playfair Display:400', 'Ubuntu:lighter,normal,bold']
      },
    })
  }
});
